<template>
  <div class="bodyBg-image">
    <img src="@/assets/img/img3.png" class="img3">
    <en-my-header></en-my-header>
    <div class="pr pt40">
      <div class="w1200 box plr25">
        <mytitle title="Stomach health risk assessment"></mytitle>
        <div class="form">
          <div class="item">
            <div class="f18 c333 pb20">1. Date of birth</div>
            <div class="myInput">
              <el-date-picker v-model="params.age" :editable="false" :clearable="false" type="date" placeholder="please choose" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
              <img src="@/assets/img/right2.png" class="right2">
            </div>
          </div>
          <div class="item">
            <my-radio v-model="params.sex" title="2. gender" dir="row" :list="sex" @click="radioClick($event,'sex')"></my-radio>
          </div>
          <div class="item">
            <div class="f18 c333 pb20">3. height（cm）</div>
            <div class="myInput">
              <el-input v-model="params.height" type="number" placeholder="please enter"></el-input>
              <div class="right2">cm</div>
            </div>
          </div>
          <div class="item">
            <div class="f18 c333 pb20">4. weight（kg）</div>
            <div class="myInput">
              <el-input v-model="params.weight" type="number" placeholder="please enter"></el-input>
              <div class="right2">kg</div>
            </div>
          </div>
          <div class="item">
            <div class="f18 c333 pb20">5. address</div>
            <div class="myInput">
              <el-cascader v-model="address" :options="options" separator='' @change="handleChange"></el-cascader>
              <img src="@/assets/img/right2.png" class="right2">
            </div>
          </div>
          <div class="item">
            <my-radio v-model="params.hp" title="6. Have you ever been infected with Helicobacter pylori?" dir="col" :list="hp" @click="radioClick($event,'hp')"></my-radio>
          </div>
          <div class="item">
            <my-radio v-model="params.jwbs" title="7. Have you ever suffered from chronic atrophic gastritis, gastric ulcer, gastric polyps, postoperative remnant stomach, hypertrophic gastritis, pernicious anemia and other diseases?" dir="col" :list="jwbs" @click="radioClick($event,'jwbs')"></my-radio>
          </div>
          <div class="item">
            <my-radio v-model="params.yjqs" title="8. Are there any gastric cancer patients among your parents, children, brothers and sisters?" dir="col" :list="yjqs" @click="radioClick($event,'yjqs')"></my-radio>
          </div>
          <div class="item">
            <my-radio v-model="params.yzsp" title="9. Do you like to eat smoked and preserved food (pickles, sauerkraut, bacon, smoked chicken, etc.)" dir="col" :list="yzsp" @click="radioClick($event,'yzsp')">
              <div class="myInput mt20" v-if="params.yzsp != 0">
                <el-select v-model="params.yzspsj" placeholder="Eat time (years)">
                  <el-option v-for="item in getYears()" :key="item.value" :label="item.label" :value="getStr(item.value)"></el-option>
                </el-select>
                <img src="@/assets/img/right2.png" class="right2">
              </div>
            </my-radio>
          </div>
          <div class="item">
            <my-radio v-model="params.gysp" title="10. Do you like to eat high-salt foods (soy tofu, ham, bean paste, etc.)" dir="col" :list="gysp" @click="radioClick($event,'gysp')">
              <div class="myInput mt20" v-if="params.gysp != 0">
                <el-select v-model="params.gyspsj" placeholder="Eat time (years)">
                  <el-option v-for="item in getYears()" :key="item.value" :label="item.label" :value="getStr(item.value)"></el-option>
                </el-select>
                <img src="@/assets/img/right2.png" class="right2">
              </div>
            </my-radio>
          </div>
          <div class="item">
            <my-radio v-model="params.yj" title="11. Do you often drink alcohol in your daily life?" dir="col" :list="yj" @click="radioClick($event,'yj')">
              <template v-if="params.yj == 1">
                <div class="myInput mt20">
                  <el-select v-model="params.yjsj" placeholder="Drinking time (years)">
                    <el-option v-for="item in getYears()" :key="item.value" :label="item.label" :value="getStr(item.value)"></el-option>
                  </el-select>
                  <img src="@/assets/img/right2.png" class="right2">
                </div>
                <div class="myInput mt20">
                  <el-select v-model="params.yjpl" placeholder="Drinking frequency (times/week)">
                    <el-option v-for="item in getNumber()" :key="item.value" :label="item.label" :value="getStr(item.value)"></el-option>
                  </el-select>
                  <img src="@/assets/img/right2.png" class="right2">
                </div>
              </template>
            </my-radio>
          </div>
          <div class="item">
            <my-radio v-model="params.cy" title="12. Do you often smoke in your daily life?" dir="col" :list="cy" @click="radioClick($event,'cy')">
              <template v-if="params.cy == 1">
                <div class="myInput mt20">
                  <el-select v-model="params.cysj" placeholder="Smoking time (years)">
                    <el-option v-for="item in getYears()" :key="item.value" :label="item.label" :value="getStr(item.value)"></el-option>
                  </el-select>
                  <img src="@/assets/img/right2.png" class="right2">
                </div>
                <div class="myInput mt20">
                  <el-select v-model="params.cypl" placeholder="Smoking amount (root/day)">
                    <el-option v-for="item in getNumber()" :key="item.value" :label="item.label" :value="getStr(item.value)"></el-option>
                  </el-select>
                  <img src="@/assets/img/right2.png" class="right2">
                </div>
              </template>
            </my-radio>
          </div>
        </div>
        <div class="ft">
          <el-button type="primary" round class="btn f20 cfff" @click="subilt">submit</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import city from '@/config/city.json';
export default {
  name: 'about',
  data(){
    return{
      submitBtn: false,
      params: {
        age: '',
        sex: '',
        height: '',
        weight: '',
        address: '',
        hp: '',
        jwbs: '',
        yjqs: '',
        yzsp: '',
        yzspsj: '',
        gysp: '',
        gyspsj: '',
        yj: '',
        yjsj: '',
        yjpl: '',
        cy: '',
        cysj: '',
        cypl: ''
      },
      address: '',
      sex: [{name: 'Man',value: 'male'},{name: 'Woman', value: 'female'}],
      options: [],
      hp: [{name: 'Yes',value: '1'},{name: 'No', value: '0'},{name: 'Uncertain', value: '2'}],
      jwbs: [{name: 'Yes',value: '1'},{name: 'No', value: '0'}],
      yjqs: [{name: 'Yes',value: '1'},{name: 'No', value: '0'}],
      yzsp: [{name: 'Never',value: '0'},{name: 'Rarely (<1 time/week)', value: '1'},{name: 'Sometimes (1-2 times/week)',value: '2'},{name: 'Often (>=3 times/week)',value: '3'}],
      gysp: [{name: 'Never',value: '0'},{name: 'Rarely (<1 time/week)', value: '1'},{name: 'Sometimes (1-2 times/week)',value: '2'},{name: 'Often (>=3 times/week)',value: '3'}],
      yj: [{name: 'Yes',value: '1'},{name: 'No', value: '0'},{name: 'Quit',value: '2'}],
      cy: [{name: 'Yes',value: '1'},{name: 'No', value: '0'},{name: 'Quit',value: '2'}],
    }
  },
  computed: {
    token(){
      return this.$store.state.token;
    }
  },
  mounted(){
    for(var i = 0; i < city.length; i++){
      city[i].value = city[i].label;
      for(var z = 0; z < city[i].children.length; z++){
        city[i].children[z].value = city[i].children[z].label;
        for(var j = 0; j < city[i].children[z].children.length; j++){
          city[i].children[z].children[j].value = city[i].children[z].children[j].label;
        }
      }
    }
    this.options = city;
  },
  methods:{
    async subilt(){
      if(this.submitBtn) return;
      
      var params = this.params;
      if(!params.age) return this.$message.error('Please select the year of birth');
      if(!params.sex) return this.$message.error('Please select gender');
      if(!params.height) return this.$message.error('Please enter height');
      if(!params.weight) return this.$message.error('Please enter weight');
      if(!params.address) return this.$message.error('Please choose an address');
      if(!params.hp) return this.$message.error('The information you filled in is not perfect');
      if(!params.jwbs) return this.$message.error('The information you filled in is not perfect');
      if(!params.yjqs) return this.$message.error('The information you filled in is not perfect');
      if(!params.yzsp) return this.$message.error('The information you filled in is not perfect');
      if(!params.gysp) return this.$message.error('The information you filled in is not perfect');
      if(!params.yj) return this.$message.error('The information you filled in is not perfect');
      if(!params.cy) return this.$message.error('The information you filled in is not perfect');
      if(this.params.yzsp != 0 && this.params.yzspsj == '') return this.$message.error('Please select the serving time');
      if(this.params.gysp != 0 && this.params.gyspsj == '') return this.$message.error('Please select the serving time');
      if(this.params.yj == 1 && this.params.yjsj == '') return this.$message.error('Please choose a drinking time');
      if(this.params.yj == 1 && this.params.yjpl == '') return this.$message.error('Please select drinking frequency');
      if(this.params.cy == 1 && this.params.cysj == '') return this.$message.error('Please choose a smoking time');
      if(this.params.cy == 1 && this.params.cypl == '') return this.$message.error('Please select smoking frequency');
        
      this.submitBtn = true;
      var params = this.params;
      params.token = this.token;
      var res = await this.$api.post('/index/screening', params)
      if (res.error_code == 0) {
        if(res.status == 1){
          this.$router.push('/en/result?isCheck=true&img=false')
        }else{
          this.$router.push('/en/result?isCheck=false&img=true')
        }
      }else if (res.error_code == 1) {
        this.$message.error(res.msg)
      }
      this.submitBtn = false;
    },
    handleChange(value){
      this.params.address = value[0] + value[1] + value[2]
    },
    radioClick(value, type){
      this.params[type] = value;
    },
    getYears(){
      const years = [];
      for (let i = 1; i <= 30; i++) {
        years.push({
          label: i + "year",
          value: i
        });
      }
      return years;
    },
    getNumber(){
      const number = [];
      for (let i = 1; i <= 20; i++) {
        number.push({
          label: i,
          value: i
        });
      }
      return number;
    },
    getStr(val){
      return val.toString();
    }
  }
}
</script>

<style scoped lang="scss">
  
  .pr{
    position: relative;
    z-index: 2;
    .box{
      background-color: #FFFFFF;
      border-radius: 20px;
      padding-bottom: 120px;
      .ft{
        padding-left: 95px;
        padding-top: 60px;
        
        .btn{
          width: 240px;
          height: 50px;
          background: #857BFE;
          border-radius: 50px;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
          padding: 0;
        }
      }
      
      .form{
        padding: 0 95px;
        padding-top: 25px;
        .item{
          margin-bottom: 60px;
          &:last-child{
            margin-bottom: 0;
          }
          .myInput{
            position: relative;
            width: 440px;
            .base{
              flex-flow: column;
              display: flex;
              .radio{
                margin-bottom: 25px;
                &:last-child{
                  margin-bottom: 0;
                }
              }
            }
            .radio{
              margin-right: 80px;
              img{
                vertical-align: middle;
                margin-right: 12px;
              }
            }
            .right2{
              position: absolute;
              right: 16px;
              top: 50%;
              transform: translateY(-50%);
            }
            /deep/ .el-select{
              width: 100%;
            }
            /deep/ .el-input__suffix{
              display: none;
            }
            /deep/ .el-cascader{
              width: 100%;
            }
            /deep/ .el-input__inner{
              padding-left: 14px;
              padding-right: 50px;
            }
            /deep/ .el-date-editor.el-input{
              width: 440px;
              
              .el-input__prefix{
                display: none;
              }
            }
          }
        }
      }
      .hd{
        width: 100%;
        height: 115px;
        border-bottom: 1px solid #EBEBEB;
        img{
          margin-right: 10px;
        }
      }
    }
  }
  .img3{
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
</style>